import { disableVersionLoadFactors, enableVersionLoadFactors } from '@/api/aqversionloadfactor';

const disableVersionLoadFactor = {
	id: 'disableVersionLoadFactor',
	selectionType: 'multiple',
	label: 'aqversionloadfactor.actions.disableVersionLoadFactor',
	functionality: 'UPDATE_AQVERSIONLOADFACTOR',
	checkAvailability: function (instance) {
		return instance && instance.disabled === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('aqversionloadfactor.actions.disableVersionLoadFactor');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				aqversionloadfactorid: registries[i].aqversionloadfactorid
			});
		}

		const data = await disableVersionLoadFactors(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableVersionLoadFactor = {
	id: 'enableVersionLoadFactor',
	selectionType: 'multiple',
	label: 'aqversionloadfactor.actions.enableVersionLoadFactor',
	functionality: 'UPDATE_AQVERSIONLOADFACTOR',
	checkAvailability: function (instance) {
		return instance && instance.disabled === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('aqversionloadfactor.actions.enableVersionLoadFactor');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				aqversionloadfactorid: registries[i].aqversionloadfactorid
			});
		}

		const data = await enableVersionLoadFactors(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

export default {
	actions: [disableVersionLoadFactor, enableVersionLoadFactor]
};
